<template>
  <div class="page-nav">
    <div class="left">
      <Back
        v-if="route?.meta?.hidden"
        @click="router.go(-1)"
        style="width: 30px; margin-right: 3px; margin-left: 8px"
      ></Back>
      <el-breadcrumb>
        <el-breadcrumb-item class="breadcrumb">{{
          route?.meta?.title
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 右侧样式 -->
    <div class="right">
      <el-dropdown placement="bottom-end">
        <div class="user-info">
          <!-- <span>GBP·UTC+0</span> -->
          <span style="margin-left: 70px">{{ username }}</span>
          <el-icon
            style="color: #000; margin-left: 20px; font-weight: 500"
            size="medium"
            :color="color"
          >
            <arrow-down></arrow-down>
          </el-icon>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <el-button type="text" @click="goUserHome">Sign out</el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router'
import { Expand, Fold, UserFilled } from '@element-plus/icons-vue'
import setupState from './setup/menuState'
import setupNav from './setup/nav'
import './index.scss'
import { Back } from '@element-plus/icons'
import { useStore } from 'vuex'
const { userInfo, isCollapse, setIsCollapse } = setupState()
const store = useStore()
const matchedRoutes = setupNav()
const route = useRoute()
const router = useRouter()
// console.log(store.state.user.userInfo.name)
const username = localStorage.getItem('userName') || ''
// const username = JSON.parse(localStorage.getItem('username'))
const goUserHome = () => {
  localStorage.removeItem('userid')
  localStorage.removeItem('Remitter')
  localStorage.removeItem('Chamberlain')
  localStorage.removeItem('user_token')
  localStorage.removeItem('reference')
  localStorage.removeItem('amount')
  localStorage.removeItem('userName')
  router.push({ name: 'login' })
}
</script>

<style lang="scss" scoped>
::v-deep.breadcrumb {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height */
  color: #000 !important;
  margin-left: 18px;
}
::v-deep.breadcrumb.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #000 !important;
}
</style>
