<template>
  <div class="page-container">
    <div :class="['page-menu-wrapper', isCollapse && 'collapsed']">
      <el-scrollbar>
        <el-menu
          ref="sidebar"
          class="page-menu"
          :default-active="activeMenu"
          @select="onChangeMenu"
          :collapse="isCollapse"
          :collapse-transition="false"
        >
          <el-menu-item class="logo">
            <div>
              <img
                :class="['logoimg', !isCollapse && 'logo-img']"
                src="https://appletservice.oss-cn-hangzhou.aliyuncs.com/STweb/TMS/LOGO%203.png?versionId=CAEQLBiBgIDOxOrNiBgiIDhmOGJjMTY4ZjdlZDQwMWE5NzNkNmVmMjlhMTE5YjQ1"
                alt=""
              />
            </div>
          </el-menu-item>
          <div v-show="!isCollapse" class="laytitle">资金系统</div>
          <template v-for="(menu, index) in menus">
            <template v-if="!menu.childrens">
              <el-menu-item
                v-if="menu.meta && !menu.meta.hidden"
                :key="index"
                :index="menu?.name"
                @click="() => onMenuClick(menu.path)"
              >
                <el-icon>
                  <!-- v-if="menu.meta && menu.meta.icon" -->
                  <el-badge
                    :max="99"
                    :value="menu.meta?.badge"
                    :hidden="!menu.meta?.badge || menu.meta?.badge <= 0"
                  >
                    <component :is="menu.meta.icon" />
                  </el-badge>
                </el-icon>
                <template #title>{{
                  menu.meta ? menu.meta.title : ''
                }}</template>
              </el-menu-item>
            </template>
            <el-sub-menu v-else :index="menu?.name" :key="`${index}-n`">
              <template #title>
                <el-icon v-if="menu.meta && menu.meta.icon">
                  <el-badge
                    :max="99"
                    :value="menu.meta?.badge"
                    :hidden="!menu.meta?.badge || menu.meta?.badge <= 0"
                  >
                    <component :is="menu.meta.icon" />
                  </el-badge>
                </el-icon>
                <span>{{ menu.meta ? menu.meta.title : '' }}</span>
              </template>
              <template v-for="(subMenu, subIndex) in menu.children">
                <template v-if="!subMenu.children">
                  <el-menu-item
                    v-if="subMenu.meta && !subMenu.meta.hidden"
                    @click="() => onMenuClick(`${menu.path}/${subMenu.path}`)"
                    :index="subMenu?.name"
                    :key="`${index}-${subIndex}`"
                  >
                    <el-icon v-if="subMenu.meta && subMenu.meta.icon">
                      <el-badge
                        :max="99"
                        :value="subMenu.meta?.badge"
                        :hidden="
                          !subMenu.meta?.badge || subMenu.meta?.badge <= 0
                        "
                      >
                        <component :is="subMenu.meta.icon" />
                      </el-badge>
                    </el-icon>
                    <template #title>{{
                      subMenu.meta ? subMenu.meta.title : ''
                    }}</template>
                  </el-menu-item>
                </template>
                <el-sub-menu :index="subMenu?.name" v-else>
                  <template #title>
                    <el-icon v-if="subMenu.meta && subMenu.meta.icon">
                      <el-badge
                        :max="99"
                        :value="subMenu.meta?.badge"
                        :hidden="
                          !subMenu.meta?.badge || subMenu.meta?.badge <= 0
                        "
                      >
                        <component :is="subMenu.meta.icon" />
                      </el-badge>
                    </el-icon>
                    <span>{{ subMenu.meta ? subMenu.meta.title : '' }}</span>
                  </template>
                  <template v-for="(thirdMenu, thirdIndex) in subMenu.children">
                    <el-menu-item
                      v-if="thirdMenu.meta && !thirdMenu.meta.hidden"
                      :key="`${index}-${subIndex}-${thirdIndex}`"
                      :index="subMenu?.name"
                      @click="
                        () =>
                          onMenuClick(
                            `${menu.path}/${subMenu.path}/${thirdMenu.path}`
                          )
                      "
                    >
                      <el-icon v-if="thirdMenu.meta && thirdMenu.meta.icon">
                        <el-badge
                          :max="99"
                          :value="thirdMenu.meta?.badge"
                          :hidden="
                            !thirdMenu.meta?.badge || thirdMenu.meta?.badge <= 0
                          "
                        >
                          <component :is="thirdMenu.meta.icon" />
                        </el-badge>
                      </el-icon>
                      <template #title>{{
                        thirdMenu.meta ? thirdMenu.meta.title : ''
                      }}</template>
                    </el-menu-item>
                  </template>
                </el-sub-menu>
              </template>
            </el-sub-menu>
          </template>
          <div class="page-nav">
            <div class="left">
              <img
                v-if="!isCollapse"
                class="menu-width"
                @click="setIsCollapse"
                src="https://appletservice.oss-cn-hangzhou.aliyuncs.com/STweb/TMS/menubig.png?versionId=CAEQLBiBgMDxrYzPiBgiIGZlZDkyYWZlNmM4ZDQ2NGVhOTdlMWQ5MDk2ZmNmNmVh"
                alt=""
              />
              <!-- <el-icon class="page-icon" @click="setIsCollapse">
              <component :is="isCollapse ? Expand : Fold" />
            </el-icon> -->
              <img
                v-else
                class="menu-small"
                @click="setIsCollapse"
                src="https://appletservice.oss-cn-hangzhou.aliyuncs.com/STweb/TMS/menusmall%20.png?versionId=CAEQLBiBgICbydbPiBgiIGIwZjQzM2E4YTg1ZDQ4NDBhZGMwYmEwMTBmOWQ2NTU4"
                alt=""
              />
            </div>
          </div>
        </el-menu>
      </el-scrollbar>
    </div>
    <div class="page-body" :style="{ paddingLeft: `${contentPadding}px` }">
      <NavBreadCrumb></NavBreadCrumb>
      <div class="page-content">
        <router-view :key="route.fullPath"></router-view>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { shallowRef, computed, watch, onMounted } from 'vue'
import { useRouter, useRoute, RouteRecordRaw } from 'vue-router'
import { useStore } from 'vuex'
import { Expand, Fold, UserFilled } from '@element-plus/icons-vue'
import './index.scss'
import NavBreadCrumb from './breadcrumb.vue'
import setupState from './setup/menuState'
import './index.scss'
const { setIsCollapse } = setupState()
const store = useStore()
const router = useRouter()
const route = useRoute()

const sidebar = shallowRef<any>(null)
const activeMenu = shallowRef<any>('')
const isCollapse = computed(() => store.getters.getIsCollapse)
const contentPadding = computed(() => (store.getters.getIsCollapse ? 63 : 200))
const menus = computed(() => store.getters.getMenu)

// 强制刷新Menu
watch(
  () => store.getters.refresh,
  () => {
    if (sidebar) sidebar.value?.$forceUpdate()
  }
)

watch(route, (to) => {
  if (to.name !== activeMenu.value) {
    activeMenu.value = to.meta.father || to.name
  }
})

const onChangeMenu = (index) => {
  activeMenu.value = index
}

const onMenuClick = (path: string) => {
  router.push(`/${path}`)
}

onMounted(() => {
  const [, menu] = menus.value
  activeMenu.value = route?.name
})
</script>
<style lang="scss" scoped>
.logo {
  margin-top: 46px;
  margin-bottom:15px
}
.logoimg {
  width: 100%;
}
.logo-img {
  width: 64px;
  height: 38px;
  margin-left: 35px;
}
.laytitle {
  text-align: center;
  margin-top: 30px;
  padding-right: 42px;
  margin-bottom: 40px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
.el-menu-item {
  text-align: center;
  width: 100%;
}
.menu-width {
  width: 20px;
  position: absolute;
  bottom: 116px;
  left: 137px;
}
.menu-small {
  width: 20px;
  position: absolute;
  bottom: 116px;
  left: 30px;
}
</style>
