import { RouteRecordRaw } from 'vue-router'
// import { HomeFilled } from '@element-plus/icons-vue'

/* Home Route Module */
const HomeRoutes: RouteRecordRaw[] = [
  {
    path: 'home',
    name: 'Home',
    meta: { title: 'Dashboard', icon: 'HomeFilled',hidden: true},
    component: () => import('@/pages/WhiteRoutes/Home/index.vue'),
  },
]
export default HomeRoutes
